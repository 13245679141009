@import '../variables';

@mixin stdBtn(
  $width: 200px,
  $height: 45px,
  $bkg-color: #ae72b7,
  $active-bkg-color: #19253a,
  $press-color: #19253a
) {
  @include primaryFont();
  font-size: 14px;
  line-height: $height + 1px;
  display: block;
  width: $width;
  height: $height;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  background-color: $bkg-color;
  border-color: #ae72b7;
  border-collapse: separate;
  border-radius: 5px;
  border-width: 0;
  outline: none;
  transition: background-color 0.3s ease-out;
  user-select: none;

  &:hover {
    background-color: #19253a;
    border-color: #19253a;
  }

  &:active {
    $press-color: darken(#19253a, 5%);
    background-color: #19253a;
    border-color: #19253a;
  }
}

@mixin stdBtnOutline {
  @include stdBtn($bkg-color: $colorWhitePure);
  border: solid thin $colorBrightPink;
  color: $colorBrightPink;
  &:hover {
    background-color: unset !important;
  }
}

@mixin action-button(
  $backgroundColor: $colorLightPurple,
  $color: $colorWhiteFloral,
  $rounded: true,
  $hover: false,
  $active: false,
  $secondary: false
) {
  display: block;
  line-height: 20px;
  min-width: 140px;
  padding: 16px 30px;

  border: none;
  background-color: $backgroundColor;
  color: $color;
  font-size: 16px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;

  // reset for usage as <a> tag
  text-decoration: none;

  &:disabled {
    background-color: $colorSilverFoil;
    cursor: default;
  }

  @if $rounded {
    border-radius: 40px;
  } @else {
    border-radius: 5px;
  }

  @if $secondary {
    background-color: transparent;
    border: 1px solid $colorBlueDark;
    color: unset;
  }

  @if $hover {
    &:hover,
    &:focus {
      color: $color;
      background-color: lighten($backgroundColor, 7%);
    }
  }

  @if $active {
    &:active {
      color: $color;
      background-color: darken($backgroundColor, 7%);
    }
  }
}

@mixin button-reset() {
  appearance: none;
  border: none;
  background: none;
  text-align: inherit;
  line-height: normal;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
}
