// ==================================================================
// Typography
// ------------------------------------------------------------------
// - Fluid Type Sizes
// - Fixed Type Sizes
// ==================================================================

$font-stack-app: 'Gilroy', 'Proxima Nova', 'Helvetica-Neue', Helvetica, Arial,
  sans-serif;

$font-stack-command: 'system', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol' !important;

$font-stack-vista: 'VistaSansAltReg';

// Fixed Font Sizes

$font-size-insane: 110px;
$font-size-large: 36px;
$font-size-x-large: 50px;
$font-size-medium: 28px;
$font-size-small: 11px;
$font-size-normal: 18px;
$font-size-body: 16px;
$font-size-button: 14px;
$font-size-small: 12px;

// Font Weights

$font-weight-bold: 600;
$font-weight-medium: 600;
$font-weight-normal: 500;
$font-weight-light: 400;

// I know I know I know I know but I forgot that when we were working out Gilroy for the app we ran into
// some insane font-weight issues around legibility. You need a 600 because 700 is too much (we may be able
// to deprecate it and just use 600)

// Relative Line Height

$line-height-heading: 1.2;
$line-height-body: 1.4;

:export {
  fontSizeMedium: $font-size-medium;
  fontSizeNormal: $font-size-normal;
  fontSizeBody: $font-size-body;
  fontSizeButton: $font-size-button;
  fontSizeSmall: $font-size-small;
}
