@import '~bravely/bravely';

.refer-a-co-worker-body {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  height: auto;
  width: 602px;
  padding: 30px;
  > div {
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      > .title {
        font-size: 18px;
        font-weight: $font-weight-medium;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: $colorBlueDarkest;
        margin-bottom: 12px;
      }

      > .sub-title {
        font-size: 16px;
        font-weight: $font-weight-light;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: $colorBlueGreyMisty;
        margin-bottom: 30px;
      }

      > .email-control {
        border: 1px solid $colorGreyBlue;
        border-radius: 4px;
        display: flex;
        align-items: center;
        height: 44px;
        padding: 4px;
        color: $colorGreyBlue;
        outline: none;
        width: 100%;
        &::before {
          content: 'To: ';
          padding: 4px;
          transform: translateY(1px);
        }
        > input[type='email'] {
          border: none;
          width: 100%;
          height: 100%;
          outline: none;
          color: $colorGreyBlue;
          &:focus {
            color: $colorBrightPink;
          }
        }
        &.focus {
          border-color: $colorBrightPink;
          &::before {
            color: $colorBrightPink;
          }
        }
        &.error {
          border-color: $colorErrorMessage;
          &::before {
            color: $colorErrorMessage;
          }
          > input[type='email'] {
            color: $colorErrorMessage;
          }
        }
      }
      > .char-count {
        text-align: right;
        font-size: x-small;
        color: $colorGreyMisty;
        padding: 2px;
        &.error {
          color: $colorErrorMessage;
        }
      }
      > label {
        color: $colorBlueDarkest;
        font-size: $font-size-small;
        margin-bottom: 10px;
      }
      > .error-msg {
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        text-align: left;
        color: $colorErrorMessage;
        height: 40px;
      }

      > .note {
        background-color: $colorGreySolitude;
        padding: 12px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: $font-weight-light;
        line-height: 20px;
        text-align: left;
        width: 100%;
        outline: none;
        border: solid thin $colorGreySolitude;
        min-height: 119px;
        resize: none;
        &.error {
          border-color: $colorErrorMessage;
          color: $colorErrorMessage;
        }
      }
    }
  }
  // Mobile display(s)
  @media ($mobile-max) {
    flex-direction: column;
    width: auto;
    height: auto;
    align-items: flex-start;
    row-gap: 10px;
    > div {
      align-items: flex-start;
      &:nth-child(2) {
        align-items: flex-start;
        > input[type='email'] {
          width: 100%;
        }
        > .send-btn {
          width: 100%;
        }
      }
    }
  }
}
