.Loader {
  border-radius: 50%; // make circle
  margin: 0 auto; // center
  position: relative; // ???

  border: 1em solid rgba(255, 255, 255, 0.2);
  border-left-color: #ffffff;

  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

.LoaderLabel {
  visibility: hidden;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
