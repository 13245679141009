// ==================================================================
// Colors
// ==================================================================

// -- Brand Colors (Legacy) ---------------------------------------------------
$colorValientViolet: #412867;
$colorGutsyGrape: #92278f;
$colorSeafoamCerulean: #67c5c7;
$colorSeafoamBright: #00c5c7;
$colorSeafoamAccent: #5fa5b4;
$colorPeriwinkleBright: #826bfa;
$colorPeriwinkleWhite: #eeedfc;

// -- Brand Colors (Current) --------------------------------------------------
$colorBlueDark: #1e3056;
$colorBlueDarkest: #18253a;
$colorBlueLight: #9aacd8;
$colorBlueGrey: #99acdd;
$colorBlueGreyMisty: #6a78a5;
$colorBlueNeutral: #2d4972;
$colorBlueToronto: #559ce5;
$colorBlueVanta: #111a28;
$colorBrightPink: #ad72b0;
$colorBrightYellow: #ffeb5c;
$colorDisabled: #d8d8d8;
$colorEeyoreMist: #6a78a5;
$colorGreenFuture: #6c9f00;
$colorGreenNeutral: #4d8e6d;
$colorGreenSerene: #005430;
$colorGreyBlue: #b9c8ea;
$colorGreyDark: #4c4e4f;
$colorGreyLight: #c5c7cc;
$colorGreyMisty: #959595;
$colorGreyMedium: #6a6d71;
$colorGreyWhite: #e8eef7;
$colorGreySolitude: #f1f4f9;
$colorMellowGold: #f4a019;
$colorProPink: #edc6c5;
$colorScarletPostcard: #bf3e0a;
$colorWhiteBlue: #f0f4fa;
$colorWhiteGrey: #f5f9ff;
$colorWhiteFloral: #f5f5f5;
$colorWhitePure: #ffffff;
$colorErrorMessage: #eb5757;
$colorLightPurple: #ae72b7;
$colorBlueGreen: #d1eedf;
$colorSilverFoil: #a9aeb5;
$colorFadedSunlight: #fcce6a;
$colorGreyReadOnly: #d8dce6;

// -- Export Colors to JS ----------------------------------------------------------
:export {
  blueDark: $colorBlueDark;
  blueDarkest: $colorBlueDarkest;
  blueLight: $colorBlueLight;
  blueNeutral: $colorBlueNeutral;
  brightPink: $colorBrightPink;
  brightYellow: $colorBrightYellow;
  eeyoreMist: $colorEeyoreMist;
  greenNeutral: $colorGreenNeutral;
  greyBlue: $colorGreyBlue;
  greyDark: $colorGreyDark;
  greyLight: $colorGreyLight;
  greyMedium: $colorGreyMedium;
  greyWhite: $colorGreyWhite;
  lightPurple: $colorLightPurple;
  scarletPostcard: $colorScarletPostcard;
  whiteBlue: $colorWhiteBlue;
  whiteGrey: $colorWhiteGrey;
  whitePure: $colorWhitePure;
  disabled: $colorDisabled;
  errorMessage: $colorErrorMessage;
  blueGreen: $colorBlueGreen;
  silverFoil: $colorSilverFoil;
  fadedSunlight: $colorFadedSunlight;
  greyReadOnly: $colorGreyReadOnly;
}

// -- iPhone Colors -----------------------------------------------------------
$colorSmsSender: #2f8cf6;
$colorSmsReceiver: #e2e1e9;

// -- Global Color Mappings ----------------------------------------------------------
$colorErrorText: $colorScarletPostcard;
