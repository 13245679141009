@import '~bravely/bravely';

$navHeight: $nav-height; // 5vh -- _dimensions.scss
$navDesktopMaxWidth: 900px;
$navPadding: 20px;
$navBackground: $colorWhitePure;
$navLinkColor: $colorBlueDarkest;
$navLinkActiveColor: $colorBrightPink;
$navLinkFontSize: $font-size-button;
$navLinkFontWeight: $font-weight-medium;
$navLinkFontSizeMobile: $font-size-normal;
$sidebarBackground: $colorWhiteBlue;
$sidebarZIndex: 999;

$navBackground__Dark: $colorBlueDarkest;
$navLinkColor__Dark: $colorWhiteBlue;
$navLinkActiveColor__Dark: $colorBrightPink;
$sidebarBackground__Dark: $colorBlueDarkest;

:global(#nav-app-container) {
  min-height: $navHeight;
  @media ($mobile-max) {
    display: inline;
  }
  @media ($desktop-min) {
    margin-bottom: $navPadding * 1.5;
  }
}

.navContainer {
  background: $navBackground;
  box-shadow: 0 0 1px $colorWhiteBlue;
  line-height: $navHeight;
  min-height: $navHeight;

  @media ($mobile-max) {
    position: sticky;
    top: 0;
    left: 0;
    z-index: $mobile-nav-z-index;
    flex: 0 0 8vh;
  }

  @media ($desktop-min) {
    position: relative;
    padding: 0 $navPadding;
  }
}

.navWrapperDesktop {
  position: relative;
  display: flex;
  align-content: center;
  max-width: $navDesktopMaxWidth;
  margin: 0 auto;
  padding: 0 $navPadding;
  background: inherit;
}

.navWrapperMobile {
  position: relative;
  line-height: $navHeight;
  text-align: center;
}

.navWrapperDashboard {
  height: 8vh;
  display: grid;
  align-items: flex-end;
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid #18253a40;
}

.navLabel {
  padding-bottom: 10px;
}

.mobileNavBack {
  display: block;
  position: absolute;
  height: $navHeight;
  padding: 0 15px;
  border: none;
  background: none;
  cursor: pointer;

  svg {
    display: block;
  }
}

.navLinkContainer {
  display: flex;
  justify-content: space-between;
  margin: auto 0 auto auto;
}

.navLink {
  display: flex;
  align-content: center;
  font-weight: $navLinkFontWeight;
  font-size: $navLinkFontSize;
  color: $navLinkColor;
  text-decoration: none;
  line-height: $navHeight;
  padding-left: $navPadding * 1.5;
  white-space: nowrap; // Prevent wrapping close to iPad portrait
  &:active,
  &:hover,
  &.selected {
    color: $navLinkActiveColor;
    text-decoration: none;
  }

  @media ($mobile-max) {
    font-size: $navLinkFontSizeMobile;
  }

  // Suppress "Contact Support" Link in Desktop
  @media ($desktop-min) {
    &[href='#contact-support'] {
      display: none;
    }
  }
}

.navDropdown {
  position: relative;

  &.navDropdownOpen {
    @media ($mobile-max) {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }
}

.navDropdownLabel {
  position: relative;
  cursor: pointer;
  padding-right: 24px;

  &::after {
    font-family: sans-serif;
    content: '\25BE';
    display: block;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(-16px, -50%);
    font-size: 12px;
    color: inherit;
  }
}

.navDropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  background: $navBackground;
  box-shadow: 0 0 2px $navLinkColor;
  border-radius: 0 0 3px 3px;
  padding: 10px 0;

  display: none;
  z-index: $sidebarZIndex;

  .navLink {
    color: $navLinkColor;
    padding: 0 $navPadding * 1.5;
    line-height: 2;
  }

  @media ($mobile-max) {
    position: relative;
    top: auto;
    left: auto;
    padding: 0;
    border-radius: 0;
  }
}

.navDropdownOpen .navDropdownMenu {
  display: block;
}

.navLogo {
  display: block;
}

.navLogoPro,
.navLogoCommand {
  svg {
    display: inline-block;
    height: 22px;
    width: auto;
    vertical-align: middle;
  }
}

.navLogoAnonymous,
.navLogoEmployee,
.navLogoOrganization {
  line-height: $navHeight;
}

.navLogoImage {
  display: inline-block;
  width: 87px;
  height: auto;
  vertical-align: middle;
}

.sidebarItemIcon {
  width: 45px;
  display: inline-block;
}

.sidebarItemIcon svg {
  height: 26px;
  width: auto;
  min-width: 26px;
  vertical-align: text-bottom;
}

.chatBubbleIcon svg {
  vertical-align: middle !important;
}

.sidebarBtn {
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  &:hover {
    background: #efefef;
  }
}

.sidebarBtnBottom {
  position: absolute;
  bottom: 60px;
  width: 260px;
}

.sidebarNavLinkList {
  flex-direction: column;
  padding: 10px 0;
}

.sidebarBurger {
  position: absolute;
  top: $navHeight * 0.5;
  right: 0;
  height: $navHeight;
  width: $navHeight;
  padding: $navPadding * 0.5;
  transform: translateY(-50%);
  z-index: $sidebarZIndex + 1;
  fill: $colorBlueDarkest;
}

.sidebarBurgerPanelOpen {
}

.sidebarBurgerDashboard {
  padding: 5px;
  right: 1rem;
  top: 4vh;
}

.sidebarBurgerSvg {
  display: block;
}

.sidebarPanel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  padding: $navHeight $navHeight $navHeight $navPadding;
  background: $sidebarBackground;
  z-index: $sidebarZIndex;
  overflow: auto;
  transform: translateX(100%);
  transition: transform 250ms ease-out;
}

.sidebarPanelOpen {
  transform: translateX(0);
}

.sidebarGlass {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  background: rgba($colorBlueDarkest, 0.5);
  opacity: 0;
  transition: opacity 250ms ease-out;
  pointer-events: none;
}

.sidebarGlassOpen {
  opacity: 1;
  pointer-events: initial;
  left: 0;
}

.admin {
  .navWrapperDesktop {
    max-width: 1170px;
    width: 100%;
    min-width: $navDesktopMaxWidth;
  }
}

// Dark Theme

.dark {
  background: $navBackground__Dark;

  .navLink {
    color: $navLinkColor__Dark;

    &:active,
    &:hover,
    &.selected {
      color: $navLinkActiveColor__Dark;
    }
  }

  .navDropdownMenu {
    .navLink {
      color: $navLinkColor;

      &:active,
      &:hover,
      &.selected {
        color: $navLinkActiveColor;
      }
    }
  }

  .sidebarPanel {
    background: $sidebarBackground__Dark;
  }

  .sidebarBurger {
    fill: $navLinkColor__Dark;
  }

  @media ($mobile-max) {
    .navDropdownMenu {
      background: none;

      .navLink {
        color: $navLinkColor__Dark;

        &:active,
        &:hover,
        &.selected {
          color: $navLinkActiveColor__Dark;
        }
      }
    }
  }
}

.dashboardStyleBackButton {
  display: flex;
  color: #ad72b0;
  font-size: 16px;
  font-weight: 600;
}

.iconStyle {
  margin-right: 8px;
  margin-top: 5px;
}

.navColorWhite {
  fill: white;
}
