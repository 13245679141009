@import '~bravely/bravely';

.flashContainer {
  background-color: inherit;
  height: 50px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 70; /* Mobile nav has a z-index of 60 */
}

.flashMessage {
  background-color: $colorBrightPink;
  color: $colorWhitePure;
  font-size: $font-size-body;
  font-weight: 600;
  padding: 15px 20px;
  text-align: center;

  animation: slideInFromTop 0.33s 1 linear;
  transition: opacity 200ms ease-out, transform 500ms ease-out;

  &.hidden {
    animation: slideOutFromTop 0.25s 1 linear;
    opacity: 0;
    transform: translateY(-50px);
  }

  &.danger {
    background-color: #ab1b10;
  }

  &.warning {
    background-color: #e6e600;
  }
}

.flashDismiss {
  cursor: pointer;
  float: right;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  position: fixed;
  top: 15px;
  right: 15px;

  &:before {
    content: 'x';
  }
}

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 100;
    transform: translateY(0);
  }
}

@keyframes slideOutFromTop {
  0% {
    opacity: 100;
    transform: translateY(0);
  }
  40% {
    transform: translateY(0);
    height: 60px;
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
