@import '~bravely/bravely';

// ProApp SCSS Variables
// Defining values in SCSS to use in places where CSS var(--name) is not supported

// [Colors] ========================================================
$color--app-bg: $colorGreyWhite;
$color--workspace-header-bg: $colorGreyBlue;
$color--nav-bg: $colorBlueDarkest;
$color--nav-highlight: $colorBlueDark;
$color--nav-chip: $colorBrightPink;
$color--nav-chip-hover: transparent;
$color--nav-text: $colorGreyBlue;
$color--subnav-bg: $colorBlueVanta;
$color--drawer-header-bg: $colorBrightPink;
$color--subnav-accent-upcoming: $colorGreenFuture;
$color--subnav-accent-past: $colorGreenSerene;
$color--subnav-accent-incomplete: $colorMellowGold;
$color--subnav-accent-checkins: $colorBlueToronto;
$color--glass-clear: transparent;
$color--glass-smokey: rgba(0, 0, 0, 0.125);
$color--toolip-bg: $colorBlueDarkest;
$color--tooltip: $colorBlueLight;
$color--card-border: $colorGreyWhite;
// [Elements] ======================================================
// - Base / Root
$base--radius-size: 4px;
$base--unit-size: 60px; // Top Bar, Nav Items, etc. Let's use this as an "atomic" unit size based on the design.
$base--timing: 360; // Unit-less for JS Export !
$base--timing-ms: #{$base--timing}ms;
$base--mobile-overlay-width: 85vw;
// - Pane
$pane--left-width: 210px;
$pane--left-width-mobile: $base--mobile-overlay-width;
$pane--left-shadow: 0 0 18px 1px rgba(0, 0, 0, 0.5); // Bigger shadow + a bit of X-offset creates a softer / deeper effect
// - Nav
$nav--icon-size: 25px;
$nav--font-size: $font-size-button;
$nav--logo-size: 120px;
// - Card
$card--radius-size: $base--radius-size;
$card--font-size: $font-size-body;
$card--font-size-small: $font-size-button;
// - CardList
$cardlist--radius-size: $base--radius-size;
$cardlist--header-font-size: $font-size-normal;
$cardlist--header-font-weight: $font-weight-medium;
$cardlist--footer-font-size: $font-size-button;
$cardlist--footer-font-weight: $font-weight-medium;
// - CardListSection
$cardlistsection--header-font-size: $font-size-body;
$cardlistsection--header-font-weight: $font-weight-medium;
// - Workspace
$workspace--header-font-size: $font-size-body;
$workspace--column-min-width: 400px; // About the width of mobile device portrait
$workspace--column-max-width: 2000px;
$workspace--body-min-height: 85vh;
$workspace--body-min-height-mobile: 70vh;
// - Tooltip
$tooltip--font-size: $font-size-small;
$tooltip--shadow: 1px 1px 4px rgba(0, 0, 0, 0.1234);
// - Drawer
$drawer--shadow: 15px 0 18px 1px rgba(0, 0, 0, 0.5); // Bigger shadow + a bit of X-offset creates a softer / deeper effect
$drawer--width-mobile: $base--mobile-overlay-width;
$drawer--width-desktop-min: 420px;
$drawer--width-desktop-max: 640px;
$drawer--header-height: 80px;
$drawer--footer-height: 100px;

// [Breakpoints] ===================================================
// @note Breakpoints can't use CSS variables `var(--name)` syntax when defining @media blocks.
//       Therefore, we need to pass this variable around with SCSS
// @link https://stackoverflow.com/questions/40722882/css-native-variables-not-working-in-media-queries
$breakpoint--pane-left: 'max-width: #{$workspace--column-min-width * 2.5}';
$breakpoint--workspace-body-columns: 'min-width: #{$workspace--column-min-width * 2.5}';
$breakpoint--mobile: 'max-width: #{$mobile-max-px}';
$breakpoint--desktop: 'min-width: #{$desktop-min-px}';

// [Z-Index] =======================================================
$z--pane-left: 20;
$z--pane-right: 10;
$z--workspace-header: 30;
$z--workspace-header-employee: 10;
$z--workspace-body: 10;
$z--drawer: 30;
$z--tooltip: 40;
// [Exports] =======================================================
:export {
  timing: $base--timing;
}
