// ==================================================================
// Dimensions
// ------------------------------------------------------------------
// Hard-coded Pixel Sizes is generally a bad idea!
// Add to this sparingly
// TODO: Deprecate hard-coded heights
// ==================================================================

$activate-height: 58px;
$card-border: 1px #e8eef7 solid;
$card-width: 500px;
$card-radius: 5px;
$content-padding: 30px;
$footer-height: 179px;
$mobile-content-padding: 10px;
$mobile-cta-fixed-height: 75px; // 45px button + 15px padding on either side
$mobile-footer-height: 296px;
$mobile-max-content-width: 420px;
$mobile-menu-height: 44px;
$mobile-nav-z-index: 60;
$nav-height: 5vh;
$mobile-nav-height: $nav-height;
$session-header-height: 134px;
